export default [
  {
    name: 'fig',
    title: 'Fig Finance - Loans API',
    desc: [
      'Fig is a SaaS company providing B2B loans infrastructure connecting lenders (banks, investment funds) and business borrwers in emerging markets. Currently operational in Nigeria, as one of the earliest team members I wear many hats across backend, frontend, and devops with a range of technologies. I work predominantly with Banking APIs, integrations to power KYC and KYB checks, as well as our own internal decision and qualification engine. Working closely with product owner and head of technology I played a critical role in successfully launching first large scale customer, opening up a potential loan book of 100k+ businesses. I was also the key developer on our Loan API product: led the design and development of core business logic     module for loan qualification and eligibility engine, for the assessment of customer KYC & financial data. Built using GoRules decision engine, MongoDB, Typescript.'
    ],
    prev: 'B2B loans infrastructure connecting borrowers and lenders in emerging markets.',
    year: "2023",
    tech: "Typescript, NodeJS, MongoDB, Mocha, AWS (EC2, Lambda, SNS, Cognito, CloudFormation)",
    resources: [
      { name:'Website', link: 'https://figfinance.io' },
    ],
    images: [
      {
        url: 'fig.png',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'fig.png',
  },
  // {
  //   name: 'cambodia',
  //   title: 'Community Feedback',
  //   desc: [
  //     'Citizen feedback app deployed in rural Cambodia for government accountability.'
  //   ],
  //   prev: 'Citizen feedback app deployed in rural Cambodia for government accountability.',
  //   year: "2022",
  //   tech: "Javascript, React, PostgreSQL, AWS",
  //   resources: [
  //     { name:'Website', link: 'https://google.com/' },
  //   ],
  //   images: [
  //     {
  //       url: 'codered-1.png',
  //       flex: 6,
  //       alt: '',
  //     }
  //   ],
  //   thumb: 'codered-thumb.png',
  // },
  {
    name: 'strategy',
    title: 'The Strategy Room',
    desc: [
      'Built MVP of a public data analysis platform to complement The Strategy Room, an an in-person digital experience that allows the public to identify the climate change policies that will best help their local area.'
    ],
    prev: 'Public data analysis platform for an in-person digital experience that allows the public to identify the climate change policies that will best help their local area.',
    year: "2023",
    tech: "Javascript, NextJS, GraphQL, AWS",
    resources: [
      { name:'Website', link: 'https://strategyroom.uk' }
    ],
    images: [
      {
        url: 'strategyroom.png',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'strategyroom.png',
  },
  {
    name: 'codered',
    title: 'Code:Red',
    desc: [
      'Code:Red was an alternate-reality game that was part of the International Federation of Red Cross and Red Crescent Societies Climate:Red global conference in 2021. It was designed to bring Red Cross volunteers together from across the world to engage with climate change topics. The game\'s narrative centred around a defunct research group (HISS Tek) that had developed a powerful AI system that had a deep understanding of climate change, but was being suppressed by an evil, anti-climate change cabal. The players were required to search YouTube, Tik Tok, mini games, live webcams, and physical clues in Red Cross offices around the world in order to solve puzzles. As a central point to the game, the HISS Tek website provided a central location for clues and puzzle answers to be input. It also provided a way for players to interact with the fictional AI system.',
      'As technical lead I developed the HISS Tek website that incorporated puzzles integrated to the game narrative, including an interactive \'AI\' terminal, carousel puzzles and hidden messages throughout the site. The game was translated into 4 languages (English, French, Spanish, Arabic) in order to cater to a wide audience and increase participation amongst Red Cross volunteers.'
    ],
    prev: 'A digital alternate-reality game designed for Red Cross volunteers across the world to address climate change.',
    year: "2021",
    tech: "Javascript, Vue, i18n language",
    resources: [
      { name:'Game Website', link: 'https://hisstek.com/' },
      { name:'Solferino Academy blog', link: 'https://solferinoacademy.com/2021/10/27/codered-a-digital-alternate-reality-game-to-tackle-the-climate-crisis/' },
    ],
    images: [
      {
        url: 'codered-1.png',
        flex: 6,
        alt: '',
      },
      {
        url: 'codered-2.png',
        flex: 4,
        alt: '',
      }
    ],
    thumb: 'codered-thumb.png',
  },
  {
    name: 'storyweb',
    title: 'StoryWeb',
    desc: [
      'Story:Web was a museum exhibit installed at the Glasgow Science Centre, on display from June 2021 until November 2021, including as part of COP26. It presented a web of stories related to climate change, which were told through objects drawn from the Tyne & Wear Archives Museum collection, and augmented with Creative Commons media such as images, sounds, and personal experiences.',
      'As technical lead I developed the exhibit using web technologies, with a view to Story:Web being published online after the exhibit. As the project required spatial audio as part of the installation, I used ThreeJS to render the story objects into 3D space, allowing the use of ThreeJS\'s spatial audio functionality. The machine running Story:Web was configured as a kiosk ensuring usage remained within the site, and set up for remote administration.'
    ],
    prev: 'An interactive visualisation of climate change stories told through museum collections, images, sounds and personal experiences.',
    year: "2020-2022",
    tech: "Javascript, ThreeJS, Webpack",
    resources: [
      { name: 'Project Website', link: 'https://storyweb.info/' },
      { name: 'The StoryWeb', link: 'https://explore.storyweb.info/' }
    ],
    images: [
      {
        url: 'storyweb-thumb.jpg',
        flex: 12,
        alt: '',
      }
    ],
    thumb: 'storyweb-thumb.jpg',
  },
  {
    name: 'metro',
    title: 'MetroFutures 2020',
    desc: [
      'Public consultation for replacement trains on Newcastle Metro, collaboration with train operator Nexus and train manufacturer Stadler. Working as technical lead within OpenLab I delivered an interactive train \'configurator\' and persona-driven stories for members of the public to express opinions on internal train design options, such as seat material, grab handle position & design, and colour scheme.',
      'Over two weeks in October 2020, 3000 people used the site to input their preference for train design. From this the most popular design was implemented into new trains.',
      'The project won \'Best Customer Initiative\' and \'Best Technical Innovation in Rolling Stock\' at The Light Rail Awards 2021.',
    ],
    prev: 'Interactive configurator and persona-driven stories to support Newcastle Metro public consultation.',
    year: "2020",
    tech: "Javascript, Vue, Bootstrap, Serverless Functions, PostgreSQL, SendGrid ",
    images: [
      { 
        url: 'mf-site.png',
        flex: 12,
        alt: 'Metro Futures site',
        
      },
    ],
    thumb: 'mf-site.png',
    resources: [
      { name:'Website', link: 'https://www.metrofutures.org.uk' },
    ]
  },
  {
    name: 'intake',
    title: 'Intake24',
    desc: ['Intake24 is a dietary monitoring tool first developed by Newcastle University in 2012 in collaboration with Food Standards Scotland. In 2019 as part, in collaboration with the University of Cambridge, Intake24 was scheduled for redevelopment for future use by Public Health England as the main tool to administer the National Diet and Nutrition Survey. I was brought onto the team in 2020 to assist in this redevelopment, where I focused on production-quality code for the survey front end, based on user feedback which was balanced against the peer-reviewed user flows. At time of writing version 4 of Intake24 is pending release in late-2022.'],
    prev: 'Worked on new version of Intake24 dietary recall tool for use by Public Health England.',
    year: "2020",
    tech: "Typescript, Vue, Vuetify, NodeJS, PostgreSQL",
    resources: "",
    images: [
      {
        url: 'intake24.png',
        flex: 12,
        alt: '',
      }
    ],
    thumb: 'intake24.png',
  },
  {
    name: 'phd',
    title: 'PhD',
    desc: [
      'Topic: Agonistic Interfaces: Prompting Debate And Reflection On Socio-Political Issues Using Digital Technology',
      'The focus of my PhD was on addressing polarisation, segmentation and influence on online discussions, where I laid out an interface design philosophy I term Agonistic Interfaces, whereby the user interface design is informed by the principals of respectful agonistic debate. This results in digital spaces where users are encouraged to come together to debate, where they are presented with thoughts and opinions that puncture their echo chamber and initiate discussions in spaces that were previous devoid of critical discussion.',
      'Previous prototypes provide an illustration of agonistic interfaces: The Moral Compass, Spotting Guide, Screenr and Speakr.'
    ],
    prev: 'Agonistic Interfaces: Prompting Debate And Reflection On Socio-Political Issues Using Digital Technology',
    year: "2016-2020",
    tech: "",
    resources: [
      { name: 'Thesis', link: 'https://nrl.northumbria.ac.uk/id/eprint/45730/'}
    ],
    images: [
      {
        url: 'speakr-thumb.png',
        flex: 4,
        alt: '',
      },
      {
        url: 'screenr-thumb.png',
        flex: 4,
        alt: '',
      },
      {
        url: 'mc-thumb.png',
        flex: 4,
        alt: '',
      }
    ],
    // thumb: 'holder.png',
  },
  {
    name: 'spkr',
    title: 'Spkr',
    desc: ['Motivated by the effects of the filter bubble and echo chamber phenomena on social media, we developed a smart home device, Spkr, that unpredictably "pushes" socio-political discussion topics into the home. The device utilised trending Twitter discussions, categorised by their socio-political alignment, to present people with a purposefully assorted range of viewpoints.'],
    prev: 'Motivated by the effects of the filter bubble and echo chamber, a smart home device, Spkr, unpredictably "pushes" socio-political discussion topics into the home.',
    year: "2019",
    tech: "",
    resources: "",
    images: [
      {
        url: 'speakr-thumb.png',
        flex: 8,
        alt: '',
      }
    ],
    thumb: 'speakr-thumb.png',
  },
  {
    name: 'monzo',
    title: 'Monzo + IFTTT',
    desc: [
      'In 2018 Fintech bank Monzo announced integration with the popular smart integration platform If This Then That (IFTTT), allowing users to automate some aspects of their banking transactions. Presenting a fascinating and never-before-seen integration into UK banking, I, together with a colleague at Northumbria University, set about investigating how users were actually using this integration. Following analysis of a rich dataset on Monzo\'s forums, we published a paper detailing the unique, and often powerful, ways people were automating and creating \'programmable money\'.'
      ],
    prev: 'Investigating a fintech integration that allowed everyday automation through \'programmable money\'',
    year: '2019',
    tech: "",
    resources: [
      { name: 'Paper', link: 'https://dl.acm.org/doi/abs/10.1145/3290605.3300481' }
    ],
    images: [
      {
        url: 'monzo-thumb.png',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'monzo-thumb.png',
  },
  {
    name: 'chatbots',
    title: 'Chatbots',
    desc: [
      'I have a broad interest in chatbots and have been involved in various things in relation to them:',
      'Co-organised a Special Interest Group at CHI 2018 around \'Chatbots for social good\' with SINTEF and other organisations',
      'Hosted workshops with students & external organisations around the design of chatbots for research/provocative purposes, e.g. breaking echo chambers',
      'Invited to Jigsaw (Alphabet) in 2017 as part of \'Bot Makers\' workshop looking at misinformation and influence around chatbots',
      'Providing advice and guidance to Newcastle undergraduate & PhD students in relation to chatbot design and UX.'
    ],
    prev: 'Various work around chatbots',
    // year: '2015-2019',
    tech: "Python, Twilio, Twitter API",
    resources: [
      { name: "Chatbots for social good", link: 'https://dl.acm.org/doi/10.1145/3170427.3185372'}
    ],
    images: [
      {
        url: 'chatbots-thumb.png',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'chatbots-thumb.png',
  },
  {
    name: 'screenr',
    title: 'Screenr',
    desc: [
      'Building upon my earlier work with The Spotting Guide, Screenr is a mobile app for co-voting and co-viewing of reality TV to encourage more critical co-viewing. It was designed with co-viewing - watching TV at the same time - at it\'s core, and incorporates features to encourages users to watch the same programme together, live, in their own homes, connected through Screenr. It features a spotting interface where viewers can record patterns and tropes being used in reality TV programmes, and also see live what is being spotted by the other viewers. To encourage co-viewing, it also features a TV guide where users can vote for which programme they would like to watch together, as well as a communal chat interface.',
    ],
    prev: 'A mobile app for co-voting and live textual tagging of reality TV to encourage more critical co-viewing.',
    year: '2017',
    tech: "Javascript, AngularJS, MongoDB",
    resources: "",
    images: [
      {
        url: 'screenr-full.png',
        flex: 8,
        alt: '',
      },
      {
        url: 'screenr-home.png',
        flex: 4,
        alt: '',
      }
    ],
    thumb: 'screenr-lr.png',
  },
  {
    name: 'spotting',
    title: 'Spotting Guide',
    desc: ['The Spotting Guide is a mobile interface for critical viewing of reality TV. Inspired by Michelin\'s iSpy books, The Spotting Guide allows viewers of reality TV to record patterns and repeated themes that they notice in the programme. Designed to be used as a second-screening tool, alongside watching reality TV on the primary screen, The Spotting Guide allows the viewer to identify techniques being used by the programme producers to build narratives or rely on tropes.'],
    prev: 'Inspired by Michelin\'s iSpy books, Spotting Guide presents an interface for critical viewing of reality TV.',
    year: '2016',
    tech: "Javascript, AngularJS, MongoDB",
    resources: "",
    images: [
      {
        url: 'sg-thumb.jpg',
        flex: 12,
        alt: '',
      }
    ],
    thumb: 'sg-thumb.jpg',
  },
  {
    name: 'moral',
    title: 'The Moral Compass',
    desc: [
      'The Moral Compass is a two part smartphone app, designed to allow users to encode a live Twitter stream with their own subjective view of the \'morality\' of the tweets within. The second part of the app allows people to explore the different encodings, using a compass interaction to see the Twitter stream. This project is founded on the idea of re-configuring the chronological, unfiltered nature of the Twitter feed, allowing it to be segemented, avoided, focused on and prompted.'
    ],
    prev: 'Visualisation tool to allow live Twitter streams to be encoded with a user\'s subjective view.',
    year: '2016',
    tech: "Javascript, AngularJS, Apache MQ",
    resources: "",
    images: [
      {
        url: 'mc-full.jpg',
        flex: 12,
        alt: '',
      }
    ],
    thumb: 'mc-full.jpg',
  },
  {
    name: 'nlfs',
    title: 'Non-League Football Supporter',
    desc: [
      '"Non-League Football Supporter is the most advanced mobile football supporter simulator ever created for English non-league football. Follow your team through thick and thin as they battle their way through the lower levels of the football pyramid."',
      'Initially built in a team for Global Game Jam, Non-League Football Supporter was iterated in 2017 into a full Android app. The game explores fan rituals and traditions and allows you to follow your team around the country providing support at each game. The game features a deep match simulation engine, and copious humour and warm lager.'
    ],
    prev: 'Non-League Football Supporter is the most advanced mobile football supporter simulator ever created for English non-league football. Follow your team through thick and thin as they battle their way through the lower levels of the football pyramid.',
    year: '2016-2019',
    tech: "Java, Android Studio",
    resources: [
      { name:'Website', link: 'http://non-league.football/' },
    ],
    images: [
      {
        url: 'nlfs-thumb.jpg',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'nlfs-thumb.jpg',
  },
  {
    name: 'masters',
    title: 'Masters by Research',
    desc: [
      'Focused around games user research, I investigated different methods for visualising and interpretting player data, oriented towards game designers and games users researchers. I implemented a telemetry suite into an Unreal Engine game (Red Orchestra) to collect large scale player data, then experimented with visualisation techniques. Settling upon the dendrogram (see above), I evaluated this visualisation technique with game designers to understand where it may be useful and how it could be applied. During this work I gave an invited talk to Crytek UK about this dendrogram visualisation approach to game user analytics.'
    ],
    prev: 'Investigated dendrogram visualisation as a tool for game designers.',
    year: '2016',
    tech: "",
    resources: [
      { name: 'Thesis', link: 'https://eprints.lincoln.ac.uk/id/eprint/23689/'}
    ],
    images: [
      {
        url: 'dendro-thumb.png',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'dendro-thumb.png',
  },
  {
    name: 'ggj',
    title: 'Global Game Jam',
    desc: [
      'Volunteer organiser for GGJ at University of Lincoln (2014, 2015) and Northumbria University (2019).',
      'I have also participated on multiple occasions (2014, 2015, 2019) building three games: Sacred Harvest, Non-League Football Supporter and ThermoSTAT, predominantly using Python and PyGame.'
    ],
    prev: 'Volunteer organiser for GGJ at University of Lincoln and Northumbria University, as well as participant',
    year: '2014',
    tech: "",
    resources: "",
    images: [
      {
        url: 'ggj.jpg',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'ggj.jpg'
  },

  {
    name: 'sacred',
    title: 'Sacred Harvest',
    desc: ['A game about developing rituals to please the pagan gods. Try to deduce what the spirit realm finds pleasing in your prayers before poor harvests cause the players to starve. Initially built in a team for Global Game Jam, Sacred Harvest was developed further and introduced to players in person through exhibits at University of Lincoln events. The overriding principles of the game were compiled into a paper that was presented at DiGRA.'],
    prev: 'A game about developing rituals to please the pagan gods. Try to deduce what the spirit realm finds pleasing in your prayers before poor harvests cause the players to starve.',
    year: '2015',
    tech: "Python, PyGame, Playstation Move controllers",
    resources: [
      { name: "Paper", link: 'https://eprints.lincoln.ac.uk/id/eprint/17559/', },
      { name: 'Gameplay', link: 'https://www.youtube.com/watch?v=0bRrhUd1eAE' },
    ],
    images: [
      {
        url: 'sh-1.png',
        flex: 6,
        alt: '',
      },
      {
        url: 'sacred-harvest.png',
        flex: 6,
        alt: '',
      }
    ],
    thumb: 'sacred-harvest.png'
  }
]
